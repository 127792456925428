<script setup >
import dayjs from "dayjs";
import LangText from "~/components/lang/LangText";
import {EnvStore} from "~/common-store/env-store";
import {GlobalPlatformInfoStore} from "~/store/global-platform-info-store";

const conf = EnvStore().config
const globalPlatformInfoStore = GlobalPlatformInfoStore();

useHead({
  titleTemplate: globalPlatformInfoStore.platformLogoFullText,
})

</script>

<template>
  <div class="flex flex-col">
    <slot/>

    <footer class="my-10 text-center">
      <p class="flex justify-center text-xs leading-5 text-gray-400">
        &copy; {{ dayjs().year() }}
        {{ globalPlatformInfoStore.platformLogoFullText }}
        Inc. All rights reserved.
      </p>
    </footer>
  </div>
</template>
